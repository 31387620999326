var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.numbers,"page":_vm.table.pageNo,"items-per-page":_vm.table.recordCount,"loading":_vm.table.loading,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.create")))])])]},proxy:true},{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.code),callback:function ($$v) {_vm.$set(_vm.filter, "code", $$v)},expression:"filter.code"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.customerCode),callback:function ($$v) {_vm.$set(_vm.filter, "customerCode", $$v)},expression:"filter.customerCode"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},on:{"input":function($event){return _vm.loadQueue()}},model:{value:(_vm.filter.customerName),callback:function ($$v) {_vm.$set(_vm.filter, "customerName", $$v)},expression:"filter.customerName"}})],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.filterExpiresAt}},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1),_c('td'),_c('td',{staticClass:"text-center"},[_c('v-simple-checkbox',{staticClass:"mt-1 mb-1",attrs:{"indeterminate":_vm.filter.repeatUsage === null},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.filterRepeatUsage),callback:function ($$v) {_vm.filterRepeatUsage=$$v},expression:"filterRepeatUsage"}})],1),_c('td',{staticClass:"text-center"},[_c('v-simple-checkbox',{staticClass:"mt-1 mb-1",attrs:{"indeterminate":_vm.filter.discontinued === null},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.filterDiscontinued),callback:function ($$v) {_vm.filterDiscontinued=$$v},expression:"filterDiscontinued"}})],1),_c('td')])]}},{key:"item.customerCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCustomerCode(item))+" ")]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCustomerName(item))+" ")]}},{key:"item.expiresAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatExpiresAt(item))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatQuantity(item))+" ")]}},{key:"item.repeatUsage",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.repeatUsage),callback:function ($$v) {_vm.$set(item, "repeatUsage", $$v)},expression:"item.repeatUsage"}})]}},{key:"item.discontinued",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.discontinued),callback:function ($$v) {_vm.$set(item, "discontinued", $$v)},expression:"item.discontinued"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.modify(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.modify")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.removeDialog(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.delete")))])])]}}])}),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pt-1",attrs:{"sm":"3","md":"2","lg":"2","xl":"1"}},[_c('v-select',{staticClass:"mt-4 text-center",attrs:{"label":_vm.$t('text.record-count'),"items":_vm.table.recordCounts,"dense":""},on:{"input":function($event){_vm.table.pageNo = 1; _vm.load();}},model:{value:(_vm.table.recordCount),callback:function ($$v) {_vm.$set(_vm.table, "recordCount", $$v)},expression:"table.recordCount"}})],1),_c('v-col',{staticClass:"pt-1"},[_c('v-pagination',{attrs:{"length":_vm.table.pageCount,"total-visible":"12"},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.table.pageNo),callback:function ($$v) {_vm.$set(_vm.table, "pageNo", $$v)},expression:"table.pageNo"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }