


















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { VoucherInfoProperties } from "./VoucherInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { VoucherNumberDialogProperties } from "./VoucherNumberDialogView.vue";
import { DateFilterDialogProperties } from "@/components/shared/DateFilterDialogView.vue";
import VoucherNumberService from "@/services/VoucherNumberService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import DateUtil from "@/utilities/DateUtil";
import CommonUtil from "@/utilities/CommonUtil";
import FilterUtil from '@/utilities/FilterUtil';

@Component({
    data: () => ({
        table: {
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
        },
        filter: {
            code: "",
            customerCode: "",
            customerName: "",
            expiresAtFrom: null,
            expiresAtTo: null,
            expiresAtNull: false,
            repeatUsage: null,
            discontinued: null,
        },
        numbers: [],
    }),
})
export default class VoucherNumberView extends Vue {
    @Prop() private properties: VoucherInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private voucherNumberDialog: VoucherNumberDialogProperties;
    @Prop() private dateFilterDialog: DateFilterDialogProperties;
    private voucherNumberService = new VoucherNumberService();
    private dateFormat: string = null;
    private queueHandle: number = 0;

    public get voucher() {
        return this.properties.voucher;
    }

    public get headers() {
        return [
            {
                text: this.$t("text.code"),
                value: "code",
                width: "200px",
            },
            {
                text: this.$t("text.customer-code"),
                value: "customerCode",
            },
            {
                text: this.$t("text.customer-name"),
                value: "customerName",
            },
            {
                text: this.$t("text.expires-at"),
                value: "expiresAt",
            },
            {
                text: this.$t("text.quantity"),
                value: "quantity",
                align: "right",
            },
            {
                text: this.$t("text.repeat-usage"),
                value: "repeatUsage",
                align: "center",
            },
            {
                text: this.$t("text.discontinued"),
                value: "discontinued",
                align: "center",
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public formatCustomerCode(item: any) {
        return item.customer ? item.customer.code : "-";
    }

    public formatCustomerName(item: any) {
        return item.customer ? item.customer.name : "-";
    }

    public formatExpiresAt(item: any) {
        const expiresAt = item.expiresAt;
        return expiresAt ? DateUtil.format(new Date(expiresAt), this.dateFormat) : "-";
    }

    public formatQuantity(item: any) {
        if (Number(item.quantity) === 0) {
            return this.$t("text.infinite");
        } else {
            return item.quantity.toFixed(0);
        }
    }

    public get filterRepeatUsage() {
        return this.$data.filter.repeatUsage;
    }

    public set filterRepeatUsage(v: any) {
        if (this.$data.filter.repeatUsage === false) {
            this.$data.filter.repeatUsage = null;
        } else {
            this.$data.filter.repeatUsage = v;
        }
    }

    public get filterDiscontinued() {
        return this.$data.filter.discontinued;
    }

    public set filterDiscontinued(v: any) {
        if (this.$data.filter.discontinued === false) {
            this.$data.filter.discontinued = null;
        } else {
            this.$data.filter.discontinued = v;
        }
    }

    public created() {
        this.load();
        this.properties.events.subscribe("load-numbers", this.load);
        this.properties.events.subscribe("filtered-date", this.filteredDate);
        this.properties.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
    }

    public destroyed() {
        this.properties.events.remove("load-numbers", this.load);
        this.properties.events.remove("filtered-date", this.filteredDate);
        this.properties.events.remove("alert-dialog-clicked", this.alertDialogClicked);
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            if (this.voucher && this.voucher.id) {
                const filter = this.$data.filter;
                const r = await this.voucherNumberService.get({
                    rowStart: rowStart,
                    rowCount: rowCount,
                    countRecord: true,
                    voucherId: this.voucher.id,
                    code: filter.code,
                    customerCode: filter.customerCode,
                    customerName: filter.customerName,
                    expiresAt: FilterUtil.filterDate(
                        filter.expiresAtNull,
                        filter.expiresAtFrom,
                        filter.expiresAtTo
                    ),
                    repeatUsage: filter.repeatUsage,
                    discontinued: filter.discontinued,
                    loadCustomer: true,
                });
                this.dateFormat = ResponseUtil.getDateFormat(r);

                var count = Math.ceil(r.data.count / rowCount);
                if (count < 1) count = 1;

                const numbers = r.data.voucherNumbers;
                numbers.forEach((x) => {
                    if (x.customer) {
                        x.customer.text = `${x.customer.code} - ${x.customer.name}`;
                    }
                });
                this.$data.numbers = numbers.sort((lhs, rhs) => rhs.id - lhs.id);

                await Vue.nextTick();
                this.$data.table.pageCount = count;
                if (pageNo > count) {
                    this.$data.table.pageNo = count;
                }
            } else {
                this.$data.numbers = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public create() {
        this.voucherNumberDialog.dateFormat = this.dateFormat;
        this.voucherNumberDialog.voucherNumber = {
            customer: {},
            code: null,
            expiresAt: null,
            quantity: 0,
            repeatUsage: false,
            discontinued: false,
            voucherId: 0,
        };
        this.voucherNumberDialog.isNew = true;
        this.voucherNumberDialog.visible = true;
    }

    public modify(item: any) {
        var tmp = CommonUtil.clone(item);
        tmp.customer = tmp.customer ?? {};

        this.voucherNumberDialog.dateFormat = this.dateFormat;
        this.voucherNumberDialog.voucherNumber = tmp;
        this.voucherNumberDialog.isNew = false;
        this.voucherNumberDialog.visible = true;
    }

    public removeDialog(item: any) {
        AlertDialogProperties.delete(this.alertDialog, item.code);
        this.alertDialog.tag = { command: "remove-number", item };
        this.alertDialog.visible = true;
    }

    public async remove(item: any) {
        try {
            await this.voucherNumberService.delete(item);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public filterExpiresAt() {
        const filter = this.$data.filter;
        this.dateFilterDialog.title = this.$t("text.expires-at").toString();
        this.dateFilterDialog.nullable = true;
        this.dateFilterDialog.dateFrom = filter.expiresAtFrom;
        this.dateFilterDialog.dateTo = filter.expiresAtTo;
        this.dateFilterDialog.dateNull = filter.expiresAtNull;
        this.dateFilterDialog.labelDateFrom = this.$t("text.date-from").toString();
        this.dateFilterDialog.labelDateTo = this.$t("text.date-to").toString();
        this.dateFilterDialog.labelDateNull = this.$t("text.empty-date").toString();
        this.dateFilterDialog.dateFormat = this.dateFormat;
        this.dateFilterDialog.tag = "expires-at";
        this.dateFilterDialog.visible = true;
    }

    public filteredDate() {
        const filter = this.$data.filter;
        if (this.dateFilterDialog.tag === "expires-at") {
            filter.expiresAtFrom = this.dateFilterDialog.dateFrom;
            filter.expiresAtTo = this.dateFilterDialog.dateTo;
            filter.expiresAtNull = this.dateFilterDialog.dateNull;
            this.load();
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "remove-number" && button === this.$t("text.yes")) {
                await this.remove(tag.item);
            }
        }
    }
}
